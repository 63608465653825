import React, { useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { List } from "@material-ui/core";
import Canticos from '../components/cardCanticos/Canticos';
import Indice from "../components/Indice";
import Loading from '../components/loading/Loading';
import Search from "../components/search/Search";
import LeerCantico from '../components/leerCantico/LeerCantico';
import Swal from "sweetalert2";
import { size } from "lodash";
import numeral from 'numeral';
import $ from "jquery";

export const Home = (props) => {
    let { type } = useParams();
    const [typeAnterior, setTypeAnterior] = useState('');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const [numeroCanticos, setNumeroCanticos] = useState('');
    const [indice, setIndice] = useState('');
    const [buscar, setBuscar] = useState('');
    const [canticos, setCanticos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showLeer, setShowLeer] = useState(false);
    const [selectCantico, setSelectCantico] = useState('');

    useEffect(() => {
        //document.getElementById("reproductorScript").remove();
        loadCanticos();
    }, [type, indice])

    const loadCanticos = async () => {
        setLoading(true);
        //Si el tipo de cantico no es igual al anterior limpiamos el filtro setIndice

        if (typeAnterior != type)
            setIndice('');

        try {
            const response = await fetch(`bsong/bsong/get?token=${user.token}&buserId=${user.id}&type=${type}&index=${indice}`);
            const json = await response.json();
            //console.log(json[0].indice)
            setCanticos(json);
            setNumeroCanticos(size(json))
            setTypeAnterior(type);
        } catch (e) {
            console.error(e);
            Swal.fire({
                title: 'Error #1',
                text: "Ha ocurrido un error.",
                confirmButtonText: 'Ok',
            })
        }
        setLoading(false);
    }

    //Buscar
    const handleSearch = (val) => {
        setBuscar(val);
    }

    //Recuperar la seleccion del la letra del indice de la derecha.
    const handleIndice = (val) => {
        setIndice(val);
    }
    //Enviamos este handle a Canticos.js y LeerCantico.js
    const handreLeerCantico = (visible, data) => {
        setSelectCantico(data);
        setShowLeer(visible);
    }

    return (
        <>
            <Loading visible={loading} />
            <div className="m-2">
                <Search handleSearch={handleSearch} value={buscar} />

                <small>
                    {numeroCanticos != '' ? 'Total: ' + numeroCanticos : ''}
                </small>

                <List>
                    {
                        size(canticos) > 0 ?
                            (canticos.filter((val) => {
                                if (buscar == "") {
                                    return val
                                }
                                if (buscar != "" && val.bsong_title.toLowerCase().includes(buscar.toLowerCase())) {
                                    return val
                                }
                            }).map((data, i) => (
                                <Canticos indice={i} jsonCanticos={data} handle={handreLeerCantico} />
                            ))) : ("")
                    }
                </List>

                <Indice handleIndice={handleIndice} type={type} />

                <LeerCantico visible={showLeer} handle={handreLeerCantico} data={selectCantico}/>
            </div>
        </>

    );
};

export default Home;