import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { ListItemSecondaryAction, Switch, ListItemIcon, ButtonGroup, Typography, Slider } from "@material-ui/core";
import Swal from "sweetalert2";
import { size } from "lodash";
import LeerCantico from '../leerCantico/LeerCantico';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Configuracion(props) {
    const classes = useStyles();
    let { type } = useParams();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const { handle, visible } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [showLeer, setShowLeer] = useState(false);
    const [selectCantico, setSelectCantico] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        setLoading(true);

        try {
            const response = await fetch(`rfavo/favoritos?token=${user.token}&buserId=${user.id}`);
            const json = await response.json();
            //console.log(json)
            setData(json);
        } catch (e) {
            console.error(e);
            Swal.fire({
                title: 'Error #1',
                text: "Ha ocurrido un error.",
                confirmButtonText: 'Ok',
            })
        }
        setLoading(false);
    }

    //Enviamos este handle a Canticos.js y LeerCantico.js
    const handreLeerCantico = (visible, data) => {
        setSelectCantico(data);
        setShowLeer(visible);
    }

    return (
        <div>

            <Dialog fullScreen open={visible} onClose={() => { handle(false) }} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => { handle(false) }} aria-label="close">
                            <i class="fas fa-times"></i>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>Favoritos</Typography>
                    </Toolbar>
                </AppBar>


                <List>
                    {
                        size(data) > 0 ?
                            (data).map((d, i) => (
                                <ListItem button divider key={i}>
                                    <ListItemText
                                        onClick={() => { handreLeerCantico(true, d); }}
                                        primary={<Typography className="text-truncate">{d.rfavo_bsong_title}</Typography>}
                                        secondary={d.rfavo_bsong_type_desc}
                                    />
                                    <ListItemSecondaryAction>
                                        <i class="fas fa-angle-right"></i>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )) : ("")
                    }
                </List>

            </Dialog>

            <LeerCantico visible={showLeer} handle={handreLeerCantico} data={selectCantico} />
        </div>
    );
}
