import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { Typography, TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import Loading from '../../components/loading/Loading';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Configuracion(props) {
    const classes = useStyles();
    const { handle, visible } = props;
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const [password, setPassword] = useState("");
    const [name, setName] = useState(user.name);
    const [lastName, setLastName] = useState(user.lastname);
    const [email, setEmail] = useState(user.email);
    const [newEmail, setNewEmail] = useState('');
    //const [avatar, setAvatar] = useState(user.avatar);

    const btnModificar = async (e) => {
        e.preventDefault();
        if (!lastName || !name) {
            Swal.fire({
                title: 'Alerta',
                text: 'El nombre y los apellidos son requeridos.',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            })
            return;
        }

        setLoading(true);
        let sendData = {
            buser_name: name,
            buser_lastname: lastName,
            buser_email: !newEmail ? user.email : newEmail,
            buser_password: password,
            buser_id:user.id
        }
        console.log(sendData);
        fetch(`buser/update?token=${user.token}`, {
            method: "PUT",
            body: JSON.stringify(sendData),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[0].status == 300) {
                    Swal.fire({
                        title: 'Alerta',
                        text: responseJson[0].mensaje,
                        confirmButtonText: 'Ok',
                    })
                }
                else {
                    let jsonUserNewData = {
                        token: user.token,
                        id: user.id,
                        email: !newEmail ? user.email : newEmail,
                        name: name,
                        lastname: lastName,
                        avatar: user.avatar,
                        status: user.status,
                        role: user.role
                    };

                    localStorage.setItem("userAccount", JSON.stringify(jsonUserNewData));
                    setEmail(jsonUserNewData.email);
                    setNewEmail(null);

                    Swal.fire({
                        title: '',
                        text: 'Su información ha sido actualizada.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error #5',
                    text: 'Ha ocurrido un error.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
                console.error(error);
            });
        setLoading(false);
    }
    return (
        <div>
            <Loading visible={loading} />
            <Dialog fullScreen open={visible} onClose={() => { handle(false) }} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => { handle(false) }} aria-label="close">
                            <i class="fas fa-times"></i>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>Cuenta de usuario</Typography>
                    </Toolbar>
                </AppBar>



                <div className="m-3 mt-5">

                    <div className="form-group">
                        <TextField
                            required
                            id="outlined-required"
                            label="Nombre"
                            variant="outlined"
                            defaultValue={name}
                            onChange={(e) => { setName(e.target.value) }}
                            fullWidth
                            color="secondary"
                        //helperText="Ingrese su email"
                        />
                    </div>

                    <div className="form-group">
                        <TextField
                            required
                            id="outlined-required"
                            label="Apellidos"
                            variant="outlined"
                            defaultValue={lastName}
                            onChange={(e) => { setLastName(e.target.value) }}
                            fullWidth
                            color="secondary"
                        //helperText="Ingrese su email"
                        />
                    </div>

                    <div className="form-group">
                        <TextField
                            id="outlined-required"
                            label="Cambiar email"
                            variant="outlined"
                            defaultValue={newEmail}
                            onChange={(e) => { setNewEmail(e.target.value) }}
                            fullWidth
                            color="secondary"
                            helperText={`Actual: ${email}`}
                        />
                    </div>

                    <div className="form-group">
                        <TextField
                            id="outlined-required"
                            label="Cambiar ontraseña"
                            variant="outlined"
                            defaultValue={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                            fullWidth
                            type="password"
                        />
                    </div>
                    <Button onClick={btnModificar} color="primary" fullWidth variant="contained">Guardar</Button>

                </div>
            </Dialog>
        </div>
    );
}
