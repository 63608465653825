import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Container } from "reactstrap";
import Notification from '../components/notification/Notification';

import './NavMenu.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function NavMenu(props) {
    const {screenName} = props;
    const classes = useStyles();

    return (
        <div className={classes.root, "mb-4"}>
            <AppBar position="static">
                <Container style={{padding:0}}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>{screenName}</Typography>
                        <Notification/>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}
