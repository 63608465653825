import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import Drawers from './Drawers';

export default function BottomMenu(props) {
    const { handleScreenName } = props;
    let history = useHistory();
    const [value, setValue] = React.useState('recents');
    const [showDrawer, setShowDrawer] = React.useState(false);

    //Abrir o cerrar el drawer
    const handleDrawer = (show) => {
        setShowDrawer(show);
    }

    //Eventos del bottom menu.
    const handleChange = (event, value) => {
        setValue(value);

        //Cambiamos el nombre de la pantalla
        if (value != 'mas')
            handleScreenName(value)

        if (value == 'Coros') {
            history.push({
                pathname: '/canticos/C',
                state: { type: 'C' }
            });
        }
        else if (value == 'Música') {
            history.push({
                pathname: '/canticos/musica/audio',
                state: { type: '' }
            });
        }
        else if (value == 'Medleys') {
            history.push({
                pathname: '/canticos/MD',
                state: { type: 'MD' }
            });
        }
        else if (value == 'Himnos') {
            history.push({
                pathname: '/canticos/H',
                state: { type: 'H' }
            });
        }
        else if (value == 'mas')
            setShowDrawer(true);
        else {
            history.push({
                pathname: '/canticos/C',
                state: { type: 'C' }
            });
        }
    };

    return (
        <>
            <Drawers visible={showDrawer} handleDrawer={handleDrawer} />

            <BottomNavigation showLabels style={{ borderTopColor: '#33aeff', borderTopWidth: 1, borderTopStyle: 'solid' }}
                className="fixed-bottom" value={value} onChange={handleChange}>
                <BottomNavigationAction
                    label="Coros"
                    value="Coros"
                    icon={<i className="fas fa-guitar"></i>}
                />
                <BottomNavigationAction
                    label="Medleys"
                    value="Medleys"
                    icon={<i className="fas fa-drum"></i>}
                />

                <BottomNavigationAction
                    label="Himnos"
                    value="Himnos"
                    icon={<i className="fas fa-book-open"></i>}
                />

                <BottomNavigationAction
                    label="Música"
                    value="Música"
                    icon={<i className="fas fa-headphones"></i>}
                />

                <BottomNavigationAction
                    label=""
                    value="mas"
                    icon={<i className="fas fa-ellipsis-v"></i>}
                />
            </BottomNavigation>
        </>

    );
}
