import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Layout from "../layout/layout/Layout";
import LoginLayout from "../layout/loginLayout/LoginLayout";

// views
import Login from "../screen/login/Login";
import Register from "../screen/login/register";
import Forget from "../screen/login/forget";

// admin Views
import Home from "../screen/Home";
//import Musica from "../screen/musica";
import Reproductor from "../screen/Player/Reproductor";

export default () => {
    return (
        <Router>
            <Switch>

                <Route path="/canticos">
                    <Layout>
                        <Switch>
                            <Route path="/canticos/:type?" exact component={Home} />
                            <Route path="/canticos/musica/audio" exact component={Reproductor} />
                        </Switch>
                    </Layout>
                </Route>

                <Route>
                    <LoginLayout>
                        <Switch>
                            <Route path="/" exact component={Login} />
                            <Route path="/login" exact component={Login} />
                            <Route path="/register" exact component={Register} />
                            <Route path="/forget" exact component={Forget} />
                        </Switch>
                    </LoginLayout>
                </Route>
               
            </Switch>
        </Router>
    );
};
