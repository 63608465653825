import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Container } from "reactstrap";
import { Button, TextField } from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2";
//

export const Forget = (props) => {
    let history = useHistory();
    moment.locale("es");
    const [email, setEmail] = useState('');

    const btnRecuperarContrasena = async (e) => {
        e.preventDefault();

        if (!email) {
            Swal.fire({
                title: 'Alerta',
                text: 'El email es requerido.',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            })
            return;
        }

        let sendData = {
            buser_email: email
        }

        fetch(`buser/forget`, {
            method: "POST",
            body: JSON.stringify(sendData),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.serverStatus == 200) {
                    Swal.fire({
                        title: 'Completado',
                        text: 'Hemos enviado la contraseña a su email. Si no lo encuentra en su bandeja de entrada verifique en el Spam.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.replace("/login");
                        } 
                    })
                }
                else if (responseJson.serverStatus == 300) {
                    Swal.fire({
                        title: '',
                        text: responseJson.mensaje,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    })
                } else {
                    Swal.fire({
                        title: '',
                        text: 'Ha ocurrido un error.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    })
                    console.error(responseJson[0].server)
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error #2',
                    text: 'Ha ocurrido un error.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
                console.error(error);
            });
    }

    return (
        <Container>
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div
                        class="card card-signin my-5"
                    >
                        <h5 class="card-title primary text-center">Recuperar contraseña</h5>
                        <div class="form-signin">

                            <div className="form-group">
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Email"
                                    variant="outlined"
                                    defaultValue={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    fullWidth
                                    color="secondary"
                                //helperText="Ingrese su email"
                                />
                            </div>

                            <Button onClick={btnRecuperarContrasena} color="primary" fullWidth variant="contained">Recuperar</Button>
                            <br />
                            <br />
                            <p class="text-center">
                                <Link to="/register">¿Ya tienes una cuenta?</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        );
};

export default Forget;
