import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Loading from '../loading/Loading';
import Swal from "sweetalert2";
import { size } from "lodash";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Notification(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const [btnShowNotificaciones, setBtnShowNotificaciones] = useState(false);
    const [showModalNotificaciones, setShowModalNotificaciones] = useState(false);
    const [notificaciones, setNotificaciones] = useState([]);

    useEffect(() => {
        loadNotificaciones();
    }, [])

    const loadNotificaciones = async () => {
        setLoading(true);
        try {
            const response = await fetch(`bsong/notificaciones?buserId=${user.id}&token=${user.token}`);
            const json = await response.json();
            //console.log(size(json[0]))
            
            if (size(json[0]) > 0) {
                setNotificaciones(json);
                setBtnShowNotificaciones(true);
            }
            else {
                setNotificaciones([]);
                setBtnShowNotificaciones(false);
            }
        } catch (e) {
            setBtnShowNotificaciones(false);
            console.error(e);
            Swal.fire({
                title: 'Error #1.202',
                text: "Ha ocurrido un error.",
                confirmButtonText: 'Ok',
            })
        }
        setLoading(false);
    }

    //Eliminar notificaciones
    const btnCerrarNotificaciones = async () => {
        setLoading(true);
        fetch(`buser/update/notificaciones?buserId=${user.id}&token=${user.token}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setBtnShowNotificaciones(false);
                setShowModalNotificaciones(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error #2',
                    text: 'Ha ocurrido un error.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
                console.error(error);
            });
        setLoading(false);
    }

    return (
        <>
            <Loading visible={loading} />
            {
                btnShowNotificaciones == true ? (
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => { setShowModalNotificaciones(true) }}
                        color="inherit">
                        <i class="fas fa-bell"></i>
                    </IconButton>
                ): null
            }

            <Dialog fullScreen open={showModalNotificaciones} onClose={btnCerrarNotificaciones} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={btnCerrarNotificaciones} aria-label="close">
                            <i class="fas fa-times"></i>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>Cánticos nuevos</Typography>
                    </Toolbar>
                </AppBar>

                <List>
                    {
                        size(notificaciones) > 0 ?
                            (notificaciones.map((data, i) => (
                                <ListItem button divider>
                                    <ListItemText
                                        primary={
                                            <Typography className="text-truncate">{data.bsong_title}</Typography>
                                        }
                                        secondary={data.bsong_type_desc}
                                    />
                                </ListItem>
                            ))) : ("")
                    }
                </List>
            </Dialog>
        </>
    );
}
