import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { ListItemSecondaryAction, Switch, ListItemIcon, ButtonGroup, Typography, Slider } from "@material-ui/core";
import $ from "jquery";
import Swal from "sweetalert2";
import { size } from "lodash";
import Loading from '../../components/loading/Loading';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    appBarBottom: {
        top: 'auto',
        bottom: 0
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LeerCantico(props) {
    const classes = useStyles();
    const { handle, visible, data } = props;
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const [favorito, setFavorito] = useState(false);
    const [cantico, setCantico] = useState([]);

    useEffect(() => {
        buscarFavorito();
    }, [data])

    const buscarFavorito = async () => {
        setLoading(true);
        try {
            //Validamos si recibe valor de la pantalla de cantico o de favoritos
            if (data.bsong_id != undefined || data.rfavo_bsong_id != undefined) {
                const response = await fetch(`bsong/bsong/get?buserId=${user.id}&token=${user.token}&buserId=${user.id}&bsong_id=${data.bsong_id != undefined ? data.bsong_id : data.rfavo_bsong_id}`);
                const json = await response.json();
                //console.log(json[0])
                setCantico(json[0]);

                if (json[0].bsong_rfavo == 0)
                    setFavorito(false);
                else setFavorito(true);

                //Estilo de las letras.
                let configuration = JSON.parse(localStorage.getItem("configuration"));
                $('#letter').css({ 'line-height': configuration.espaciado + 'px', 'font-size': configuration.letra + 'px', 'text-align': configuration.alineacion });
            }

        } catch (e) {
            console.error(e);
            Swal.fire({
                title: 'Error #1.03',
                text: "Ha ocurrido un error.",
                confirmButtonText: 'Ok',
            })
        }
        setLoading(false);
    }

    const cerrarModal = () => {
        handle(false, '')
    }

    //Agregar a la lista.
    const btnAgregarFavorito = () => {
        setLoading(true);
        let sendData = {
            rfavo_buser_id: user.id,
            rfavo_bsong_id: data.bsong_id
        }

        fetch(`rfavo/add?token=${user.token}`, {
            method: "POST",
            body: JSON.stringify(sendData),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setFavorito(true);
                Swal.fire({
                    title: '',
                    text: 'El cántico ha sido agregado a la lista de favoritos.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error #1',
                    text: 'Ha ocurrido un error.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
                console.error(error);
            });
        setLoading(false);
    }

    //Eliminar de la lista.
    const btneliminarFavorito = () => {
        //e.preventDefault();
        setLoading(true);
        let sendData = {
            rfavo_id: data.bsong_rfavo
        }

        fetch(`rfavo/delete?token=${user.token}`, {
            method: "DELETE",
            body: JSON.stringify(sendData),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setFavorito(false);
                Swal.fire({
                    title: '',
                    text: 'El cántico ha sido eliminado de la lista de favoritos.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error #1',
                    text: 'Ha ocurrido un error.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
                console.error(error);
            });
        setLoading(false);
    }

    //Agregamos la letra del cantico al blockquote. De esta manera la app puede poner las etiquetas <br/> porque lo interpreta el json como HTML.
    $(document).ready(function () {
        $('#letter').html(cantico.bsong_letter);
        //$('#letter').css({ 'line-height': configuration.espaciado + 'px', 'font-size': configuration.letra + 'px', 'text-align': configuration.alineacion });
    });


    return (
        <div>
            <Dialog fullScreen open={visible} onClose={cerrarModal} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={cerrarModal} aria-label="close">
                            <i class="fas fa-times"></i>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>{cantico.bsong_title}</Typography>
                    </Toolbar>
                </AppBar>

                <Loading visible={loading} />
                <blockquote className="p-5" id="letter"></blockquote>

                {
                    data.rfavo_bsong_id == undefined ?
                        (
                        <AppBar position="fixed" color="" className={classes.appBarBottom}>
                            <Toolbar style={{ justifyContent: 'flex-end' }}>

                                {
                                    favorito != 0 ?
                                        (
                                            <IconButton onClick={btneliminarFavorito} className="ml-2" edge="start">
                                                <i style={{ color: 'red' }} class="fas fa-heart"></i>
                                            </IconButton>
                                        ) :
                                        (
                                            <IconButton onClick={btnAgregarFavorito} className="ml-2" edge="start">
                                                <i style={{ color: 'red' }} class="far fa-heart"></i>
                                            </IconButton>)
                                }
                            </Toolbar>
                        </AppBar>
                        ) : null
                }

            </Dialog>
        </div>
    );
}
