import React from 'react';
import {useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Favoritos from '../../favoritos/Favoritos';
import Configuracion from '../../configuracion/Configuracion';
import CuentaUsuario from '../../cuentaUsuario/CuentaUsuario';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function Drawers(props) {
    const { visible, handleDrawer } = props;
    let history = useHistory();
    const [showIndice, setShowIndice] = React.useState(false);
    const [showFavoritos, setShowFavoritos] = React.useState(false);
    const [showConfig, setShowConfig] = React.useState(false);
    const [showCuentaUsuario, setShowCuentaUsuario] = React.useState(false);
    const classes = useStyles();

    //abrir o cerrar drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        handleDrawer(!visible);
    };

    //abrir o cerrar indice
    const handleIndice = (show) => {
        setShowIndice(show);
    }

    //Abrir o cerrar favoritos
    const handleFavoritos = (show) => {
        setShowFavoritos(show);
    }

    //Abrir o cerrar el modal de config
    const handleConfig = (show) => {
        setShowConfig(show);
    }

    //cuenta de usuario
    const handleUsuario = (show) => {
        setShowCuentaUsuario(show);
    }

    const btnSalir = () => {
        localStorage.clear();
        history.replace("/login");
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>

            <List>
                {[{ label: 'Índice', action: () => { handleIndice(true) }, icon: 'fas fa-list-ul', display:'none' },
                    { label: 'Favoritos', action: () => { handleFavoritos(true) }, icon: 'fas fa-heart', display: 'visible' }].map((data, index) => (
                        <ListItem button key={index} onClick={data.action} style={{ display: data.display}}>
                        <ListItemIcon><i class={data.icon}></i></ListItemIcon>
                        <ListItemText primary={data.label} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {[{ label: 'Configuración', action: () => { handleConfig(true) }, icon: 'fas fa-cog', display: 'visible' },
                    { label: 'Cuenta de usuario', action: () => { handleUsuario(true) }, icon: 'fas fa-user', display: 'visible' },
                    { label: 'Salir', action: btnSalir, icon: 'fas fa-door-closed', display: true }].map((data, index) => (
                        <ListItem button key={index} onClick={data.action} style={{ display: data.display }}>
                        <ListItemIcon><i class={data.icon}></i></ListItemIcon>
                        <ListItemText primary={data.label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div>
            <Favoritos visible={showFavoritos} handle={handleFavoritos} />
            <Configuracion visible={showConfig} handle={handleConfig} />
            <CuentaUsuario visible={showCuentaUsuario} handle={handleUsuario} />

            <React.Fragment>
                <Drawer anchor={'bottom'} open={visible} onClose={toggleDrawer(false)}>
                    {list('bottom')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
