import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        backgroundColor:'rgb(51 174 255 / 35%)',
        zIndex: theme.zIndex.drawer + 1,
        flexDirection:'column',
        color:'#fff'
    },
}));

export default function Loading(props) {
    const { visible } = props;
    const classes = useStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={visible}>
                <CircularProgress style={{ height: 60, width: 60 }} />
                <p>Cargando...</p>
            </Backdrop>
        </div>
    );
}