import React, { useEffect, useState } from "react";
//import { Link, useHistory } from 'react-router-dom';
import Swal from "sweetalert2";
import { size } from "lodash";
import Loading from '../../components/loading/Loading';
import Search from '../../components/search/Search';
import Indice from "../../components/Indice";
import $ from "jquery";
import "./style.css";

export const Reproductor = (props) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const [loading, setLoading] = useState(false);
    const [indice, setIndice] = useState("");
    const [audio, setAudio] = useState([]);
    const [numeroCanticos, setNumeroCanticos] = useState('');
    const [buscar, setBuscar] = useState('');
    //Variable para cargar y borrar el script de reproductor.
    const script = document.createElement('script');

    useEffect(() => {
        loadData();
    }, [indice])

    useEffect(() => {
        //Eliminamos el script del reproductor cuando salimos de la pantalla.
        return () => {
            $(".pause").click();
            document.body.removeChild(script);
        }
    }, []);

    function cargarScript() {
        script.src = "./assets/js/musicplayer.js";
        script.async = true;
        script.id = "reproductorScript";
        document.body.appendChild(script);
    }

    const loadData = async () => {
        setLoading(true);

        try {
            const response = await fetch(`bsong/bsong/audio?token=${user.token}&buserId=${user.id}&index=${indice}&letter=${buscar}`);
            const json = await response.json();
            setAudio(json);
            setNumeroCanticos(size(json))
            //Se cargar el script del repoductor cuando se haya mostrado la lista de los coros.
            cargarScript();
        } catch (e) {
            console.error(e);
            Swal.fire({
                title: 'Error #1',
                text: "Ha ocurrido un error.",
                confirmButtonText: 'Ok',
            })
        }
        setLoading(false);
    }

    //Indice de la derecha.
    const handleIndice = (val) => {
        setIndice(val);
    }

    //Buscar
    const handleSearch = (val) => {
        setBuscar(val);
        cargarScript();
    }

    return (
        <div className="m-2">
            <Loading visible={loading} />

            <Search handleSearch={handleSearch} value={buscar} helperText="" />

            <small>
                {numeroCanticos != '' ? 'Total: ' + numeroCanticos : ''}
            </small>

            <div className="reproductor">
                <ul className="playlist">
                    {
                        size(audio) > 0 ?
                            audio.filter((val) => {
                                if (buscar == "") {
                                    return val
                                }
                                if (buscar != "" && val.bsong_title.toLowerCase().includes(buscar.toLowerCase())) {
                                    return val
                                }
                            }).map((data, i) => (
                                <li className="playlist-item"
                                    key={i}
                                    data-read={data.bsong_title}
                                >
                                    <a className="text-truncate" href={data.bsong_audio_path}>{data.bsong_title}</a>
                                </li>
                            )) : ("")
                    }
                </ul>
            </div>

            <Indice handleIndice={handleIndice} type="M" />
        </div>
    );
};

export default Reproductor;