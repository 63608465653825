import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { ListItemSecondaryAction, Switch, ListItemIcon, ButtonGroup, Typography, Slider } from "@material-ui/core";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Configuracion(props) {
    const classes = useStyles();
    const { handle, visible } = props;
    const [configuration, setConfiguration] = useState(JSON.parse(localStorage.getItem("configuration")));
    const [modoNocturno, setModoNocturno] = useState(JSON.parse(localStorage.getItem("modoNocturno")));

    useEffect(() => {
        loadConfiguration();
    }, [])

    const loadConfiguration = () => {
        //console.log(configuration);
        if (configuration == null || configuration == "")
            setConfiguration({ alineacion: 'left', letra: '14', espaciado: '24' });
        else setConfiguration(JSON.parse(localStorage.getItem("configuration")));

        //console.log(localStorage.getItem("modoNocturno"));
        if (modoNocturno == null || modoNocturno == "")
            setModoNocturno(false);
        else setModoNocturno(localStorage.getItem("modoNocturno"));
    }

    const handleLetra = (event, newValue) => {
        setConfiguration({ ...configuration, letra: newValue });
        localStorage.setItem("configuration", JSON.stringify(configuration));
        $('.changeLetter').css({ 'line-height': configuration.espaciado + 'px', 'font-size': configuration.letra + 'px', 'text-align': configuration.alineacion });
    };

    const handleEspaciado = (event, newValue) => {
        setConfiguration({ ...configuration, espaciado: newValue });
        localStorage.setItem("configuration", JSON.stringify(configuration));
        $('.changeLetter').css({ 'line-height': configuration.espaciado + 'px', 'font-size': configuration.letra + 'px', 'text-align': configuration.alineacion });
    };

    const btnAlineacion = (text) => {
        let conf = { ...configuration, alineacion: text };
        setConfiguration(conf);
        localStorage.setItem("configuration", JSON.stringify(conf));
        $('.changeLetter').css({ 'line-height': conf.espaciado + 'px', 'font-size': conf.letra + 'px', 'text-align': conf.alineacion });
    }

    const cbModoNocturno = () => {
        setModoNocturno(!modoNocturno)
        localStorage.setItem("modoNocturno", !modoNocturno);

        //console.log(!modoNocturno);
        if (!modoNocturno == true)
            $('head').append($('<link id="modoOscuro" rel="stylesheet" type="text/css" />').attr('href', 'assets/css/modoOscuro.css'));
        else $("#modoOscuro").remove();
    }

    $(document).ready(function () {
        $('.changeLetter').css({ 'line-height': configuration.espaciado + 'px', 'font-size': configuration.letra + 'px', 'text-align': configuration.alineacion });
    });

    return (
        <div>

            <Dialog fullScreen open={visible} onClose={() => { handle(false) }} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => { handle(false) }} aria-label="close">
                            <i class="fas fa-times"></i>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>Configuración</Typography>
                    </Toolbar>
                </AppBar>

                <List>

                    <ListItem button divider>
                        <ListItemIcon>
                            {
                                modoNocturno == true ? (<i class="fas fa-moon"></i>) : (<i class="fas fa-sun"></i>)
                            }
                        </ListItemIcon>
                        <ListItemText id="cbEstilo" primary={"Modo nocturno"} />
                        <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                onChange={cbModoNocturno}
                                checked={modoNocturno}
                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

                <p className="m-3 changeLetter">
                    Sea llena mi boca de tu alabanza, de tu gloria todo el día.
                </p>

                <div className="m-3">
                    <Typography id="discrete-slider-small-steps" gutterBottom>Alineación</Typography>
                    <ButtonGroup color="primary" fullWidth variant="text" aria-label="text primary button group">
                        <Button onClick={(e) => { btnAlineacion('left')}} className="rounded-0"><span class="fas fa-align-left primary"></span></Button>
                        <Button onClick={(e) => { btnAlineacion('center') }} ><span class="fas fa-align-center primary"></span></Button>
                        <Button onClick={(e) => { btnAlineacion('right') }}  className="rounded-0"><span class="fas fa-align-right primary"></span></Button>
                    </ButtonGroup>
                </div>

                <div className="m-3">
                    <Typography id="discrete-slider-small-steps" gutterBottom>Tamaño de letra</Typography>
                    <Slider
                        name="letra"
                        defaultValue={configuration.letra}
                        //getAriaValueText={(e) => { sliderLetra(e)}}
                        onChange={handleLetra}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks
                        min={8}
                        max={24}
                        valueLabelDisplay="auto"
                    />
                </div>

                <div className="m-3">
                    <Typography id="discrete-slider-small-steps" gutterBottom>Espaciado entre líneas</Typography>
                    <Slider
                        name="espaciado"
                        onChange={handleEspaciado}
                        defaultValue={configuration.espaciado}
                        //getAriaValueText={handleEspaciado}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks
                        min={20}
                        max={35}
                        valueLabelDisplay="auto"
                    />
                </div>

                <div className="m-3 text-center">
                    <Typography id="discrete-slider-small-steps" gutterBottom>Versión 3.0.0</Typography>
                </div>

            </Dialog>
        </div>
    );
}
