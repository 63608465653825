import React, {useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Container } from "reactstrap";
import { Button, TextField, Box } from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2";
import { size } from "numeral";
//

export const Register = (props) => {
    let history = useHistory();
    moment.locale("es");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const btnRegister = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            Swal.fire({
                title: 'Alerta',
                text: 'El email y la contraseña son requeridos.',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            })
            return;
        }

        let sendData = {
            buser_email: email,
            buser_password: password
        }

        fetch(`buser/register`, {
            method: "POST",
            body: JSON.stringify(sendData),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[0].status == 200) {
                    //localStorage.setItem("userAccount", JSON.stringify(responseJson));
                    history.replace("/login");
                }
                else if (responseJson[0].status == 300) {
                    Swal.fire({
                        title: 'Alerta',
                        text: responseJson[0].mensaje,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    })
                }
                else {
                    Swal.fire({
                        title: 'Error #1',
                        text: 'Ha ocurrido un error.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    })
                    console.error(responseJson)
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error #2',
                    text: 'Ha ocurrido un error.',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
                console.error(error);
            });
    }

    return (
        <Container>
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div
                        class="card card-signin my-5"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.54)" }}
                    >
                        <h5 class="card-title primary text-center">Crear cuenta</h5>
                        <div class="form-signin">

                            <div className="form-group">
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Email"
                                    variant="outlined"
                                    defaultValue={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    fullWidth
                                    color="secondary"
                                //helperText="Ingrese su email"
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Contraseña"
                                    variant="outlined"
                                    defaultValue={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    fullWidth
                                    type="password"
                                />
                            </div>

                            <Button onClick={btnRegister} color="primary" fullWidth variant="contained">Crear</Button>
                            <br />
                            <br />
                            <p class="text-center">
                                <Link to="/">¿Ya tienes una cuenta?</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Register;