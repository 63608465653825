import React, {useEffect } from "react";
import { Container } from "reactstrap";
import "./styleLoginLayout.css";
import $ from "jquery";

export const LoginLayout = (props) => {
    const modoNocturno = JSON.parse(localStorage.getItem("modoNocturno"));

    useEffect(() => {
        changeMode();
    }, [])

    const changeMode = () => {
        //console.log(modoNocturno);
        if (modoNocturno === true)
            $('head').append($('<link id="modoOscuro" rel="stylesheet" type="text/css" />').attr('href', 'assets/css/modoOscuro.css'));
        else $("#modoOscuro").remove();
    }

  return (
    <div className="body-login">
      <Container>{props.children}</Container>
    </div>
  );
};
export default LoginLayout;
