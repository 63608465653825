import React, { Component } from "react";
import Routers from "./router";
import "./custom.css";
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            '&:hover': { // changes colors for button hover state
                backgroundColor: "red",
                color: "greeb",
            },
            main: "#33AEFF",
        },
        secondary: {
            main: "#019aff"
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 30,
                textTransform: 'none',
                border: 2,
                borderStyle:'solid',
                borderColor: '#33AEFF',
                fontSize: 16,
            },
            label: { color: "#fff" },
        },
    },
    typography: {
        fontSize: 12,
        h3: {
            fontWeight: 700,
            fontSize: '2.2rem'
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.75rem'
        },
        h5: {
            fontWeight: 500
        },
        h6: {
            fontWeight: 500
        }
    }
});

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
        <ThemeProvider theme={theme}>
                <Routers />
        </ThemeProvider>
    );
  }
}
