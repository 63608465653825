import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default function Search(props) {
    const { handleSearch, value, helperText } = props;

    return (
        <div className="m-2 mb-4">
            <InputLabel htmlFor="input-with-icon-adornment">Buscar</InputLabel>
            <TextField
                fullWidth
                id="input-with-icon-adornment"
                defaultValue={value}
                onChange={(e) => { handleSearch(e.target.value) }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <i className="fas fa-search"></i>
                        </InputAdornment>
                    ),
                }}
                helperText={helperText}
            />
            </div>
    );
}
