import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Loading from '../components/loading/Loading';
import Swal from "sweetalert2";
import { size } from "lodash";

export default function Inidice(props) {
    const { handleIndice, type } = props;
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const [loading, setLoading] = useState(false);
    const [letras, setLetras] = useState(type);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadIndice();
    }, [type])

    const loadIndice = async () => {
        setLoading(true);

        try {
            const response = await fetch(`bsong/bsong/indice?token=${user.token}&type=${type}`);
            const json = await response.json();
            //console.log(json)
            setData(json);
        } catch (e) {
            console.error(e);
            Swal.fire({
                title: 'Error #1',
                text: "Ha ocurrido un error.",
                confirmButtonText: 'Ok',
            })
        }
        setLoading(false);
    }

    const btnHandleIndice = (val) => {
        handleIndice(val);
    }

    return (
        <>
            <Loading visible={loading} />
            <div style={{ position: 'fixed', right: 7, top: 80, height: '100%' }}>
                <ul className="list-unstyled" style={{ fontSize: 12, color: "#33aeff" }}>
                    <li onClick={() => { btnHandleIndice('') }} className="mb-2"><i className="fas fa-redo-alt" style={{ fontSize: 10 }}></i></li>
                    {
                        size(data) > 0 ?
                            (data).map((d, i) => (
                                <li key={i} onClick={() => btnHandleIndice(d.indice_letter)} className="mb-2">{d.indice_letter}</li>
                            )) : ("")
                    }
                </ul>
            </div>
        </>
    );
}