import React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, ButtonGroup, Typography } from "@material-ui/core";


export default function Canticos(props) {
    const { indice, jsonCanticos, handle } = props;

    return (
        <ListItem divider button
            key={indice}
            onClick={() => { handle(true, jsonCanticos); }}
            //className="bg-white"
        >
            <ListItemText 
                primary={
                    <Typography className="text-truncate">{jsonCanticos.bsong_title}</Typography>
                } />

            <ListItemSecondaryAction>
                <i class="fas fa-angle-right"></i>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
