import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Zoom, useScrollTrigger, makeStyles, Fab } from "@material-ui/core";
import NavMenu from "../../components/NavMenu";
import BottomMenu from "../../components/navmenu/bottom/bottomMenu";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(3),
    },
}));

function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#root');
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export const Layout = (props) => {
    $('body').css('background-color','#f5f5f5');
    let history = useHistory();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userAccount")));
    const modoNocturno = JSON.parse(localStorage.getItem("modoNocturno"));
    const [screenName, setsCreenName] = useState('Coros');

    useEffect(() => {
        changeMode();
    }, [])

    if (user == undefined || user == "")
        return <Redirect to={{ pathname: '/login' }} />

    const changeMode = () => {
        if (modoNocturno == true)
            $('head').append($('<link id="modoOscuro" rel="stylesheet" type="text/css" />').attr('href', 'assets/css/modoOscuro.css'));
        else $("#modoOscuro").remove();
    }

    //Obtenemos el nombre de la pantalla de acuerdo a la seleccion que se haya hecho en el bottom menu
    const handleScreenName = (val) => {
        setsCreenName(val);
    }
  return (
      <>
          <NavMenu screenName={screenName} />
          <Container className="pb-5">
              {props.children}

              <ScrollTop {...props}>
                  <Fab color="secondary"  aria-label="scroll back to top">
                      <i className="fas fa-chevron-up text-white"></i>
                  </Fab>
              </ScrollTop>
          </Container>
          <BottomMenu handleScreenName={handleScreenName} />
    </>
  );
};
export default Layout;